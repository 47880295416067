<template>
  <!--begin::details View-->
  <form autocomplete="off">
    <div class="ps-5 pe-5">
      <div class="row mb-4">
        <div class="col-md-4">
          <label class="fw-bold text-gray-600">Persona</label>
          <div class="dp__input_wrap text-gray-600">
            <input
              type="text"
              class="form-control"
              placeholder=""
              aria-label=""
              v-model="tecnicoSocieta.nominativo_completo"
              @input="
                getPersone($event.target.value);
                inputFocus = index;
              "
              autocomplete="off"
            />
            <input
              type="hidden"
              class="form-control"
              v-model="tecnicoSocieta.id_persona"
            />
            <ListPersone
              :list="tecnici"
              @hideList="
                resetLegali();
                inputFocus = null;
              "
              @selectedLegale="
                tecnicoSocieta.nominativo_completo = $event.name;
                tecnicoSocieta.id_persona = $event.value;
                $emit('setPersona', {
                  ...tecnicoSocieta,
                  nominativo_completo: $event.name,
                  id_persona: $event.value,
                });
                inputFocus = null;
                resetLegali();
              "
              :id="tecnicoSocieta.id_persona"
              :focusOnInput="inputFocus === index"
            ></ListPersone>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              class="dp__icon dp__clear_icon dp__input_icons"
              @click="
                tecnicoSocieta.nominativo_completo = '';
                tecnicoSocieta.id_persona = null;
              "
            >
              <path
                d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
              ></path>
              <path
                d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Carica</label
          >
          <SelectInput
            name="carica"
            id="carica"
            :options="tipo_tecnico"
            :value="tecnicoSocieta.id_tipo_tecnico"
            @changeSelect="
              tecnicoSocieta.id_tipo_tecnico = parseInt($event);
              $emit('setPersona', {
                ...tecnicoSocieta,
                id_tipo_tecnico: parseInt($event),
              });
            "
          />
        </div>
        <div class="col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Data inizio</label
          >
          <Datepicker
            v-model="tecnicoSocieta.dataInizio"
            :format="format"
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            inputClassName="h-28"
            locale="it"
            @update:modelValue="
              $emit('setPersona', {
                ...tecnicoSocieta,
                dataInizio: $event,
              })
            "
            autoApply
          />
        </div>
        <div class="col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Data fine</label
          >
          <Datepicker
            v-model="tecnicoSocieta.dataFine"
            :format="format"
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            inputClassName="h-28"
            locale="it"
            @update:modelValue="
              $emit('setPersona', {
                ...tecnicoSocieta,
                dataFine: $event,
              })
            "
            autoApply
          />
        </div>

        <div
          class="col-md-1 fw-bold text-gray-700 text-center mt-5 text-danger"
        >
          <button
            class="badge bg-light-danger rounded fs-5"
            @click.prevent="$emit('deleteItem')"
          >
            <i class="bi bi-trash text-danger"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
} from "vue";
import ListPersone from "../../utility/ListPersone.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { useStore } from "vuex";
import "vue3-date-time-picker/dist/main.css";
export default defineComponent({
  name: "add-tecnico",
  components: {
    ListPersone,
    SelectInput,
    Datepicker,
  },
  emits: ["deleteItem", "setPersona"],
  props: {
    index: {
      type: Number,
      required: true,
    },
    tecnico: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tipo_tecnico = computed(() =>
      store.getters.getStateFromName("tipo_tecnico")
    );
    const keys = ref("tt");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (!tipo_tecnico.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const tecnicoSocieta = ref({});

    onMounted(() => {
      tecnicoSocieta.value = props.tecnico ? props.tecnico : {};
    });

    const getPersone = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const inputFocus = ref(null);

    return {
      tecnicoSocieta,
      getPersone,
      resetLegali,
      spliceLegali,
      tecnici: computed(() => store.getters.personeSocieta),
      tipo_tecnico,
      inputFocus,
      format,
    };
  },
});
</script>
