<template>
  <div
    style="position: relative; width: 100%"
    v-click-away="onClickAway"
    v-show="list.length"
    :key="id"
  >
    <ul class="list" v-if="list.length">
      <li
        class="text-hover-primary"
        style="cursor: pointer"
        v-for="legale in list"
        :key="legale.id"
        :value="legale.id"
        @click.prevent="
          $emit('selectedLegale', {
            value: legale.id,
            name: `${legale.cognome.trim()},${legale.nome.trim()} ${
              legale.data_nascita
            }`,
          })
        "
        @touchstart.prevent="
          $emit('selectedLegale', {
            value: legale.id,
            name: `${legale.cognome.trim()},${legale.nome.trim()} ${
              legale.data_nascita
            }`,
          })
        "
      >
        <a
          >{{ legale.cognome.trim() }}, {{ legale.nome.trim() }}
          {{ legale.data_nascita }}</a
        >
      </li>
      <li class="pt-2" v-show="!no_new">
        <a
          href=""
          data-bs-toggle="modal"
          data-bs-target="#modal_add-anagrafica-persona"
          >Nuova anagrafica</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
  name: "list-persone",
  props: ["list", "visible", "id", "no_new"],
  emits: ["selectedLegale", "hideList", "aggiungiPersona", "selectedElement"],
  directives: {
    ClickAway: directive,
  },
  setup(props, { emit }) {
    const onClickAway = () => {
      emit("hideList");
    };
    return { onClickAway };
  },
};
</script>

<style>
.list {
  position: absolute;
  z-index: 999;
  background-color: #f3f6f8;
  border: 1px solid #a3a7b8;
  border-radius: 0.475rem;
  list-style-type: none;
  width: 100%;
  padding-top: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}
</style>
