<template>
  <!--begin::details View-->
  <div class="card shadow mb-5 mb-xl-10">
    <loading :active="isLoading" :is-full-page="fullPage"></loading>
    <div class="ps-5 pe-5 pt-6">
      <h6>Tecnici società</h6>
    </div>
    <hr />
    <div v-if="!loadedTecnici">
      <div class="mx-auto my-auto text-center">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
    </div>
    <div
      v-else-if="loadedTecnici && !elencoTecnici.length"
      class="mx-auto my-auto text-center pt-5 mb-5 pb-5"
    >
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div v-else>
      <div class="ps-5 pe-5">
        <div class="alert alert-warning" role="alert" v-if="proponiDati">
          <b
            >Attenzione: Sono presentati i dati relativi ai "tecnici società"
            dell'anno {{ stagioneSelected - 1 }}</b
          >
          <br />
          <span v-if="!readOnly && puo_utilizzare_pulsanti_sezioni">
            Cliccando su "salva modifiche" i tecnici verranno riconfermati per
            la stagione selezionata</span
          >
        </div>
        <div class="row mb-4">
          <div class="col-md-4">
            <label class="fw-bold text-gray-600">Persona</label>
          </div>
          <div class="col-md-3">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Carica</label
            >
          </div>
          <div class="col-md-2">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Data inizio</label
            >
          </div>
          <div class="col-md-2">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Data Fine</label
            >
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-1"></div>
        </div>
      </div>
      <div
        class="ps-5 pe-5"
        v-for="(persona, index) in elencoTecnici"
        :key="persona.id"
      >
        <div class="row mb-4">
          <div class="col-md-4 align-self-center">
            <div class="dp__input_wrap text-gray-600">
              <!---->
              <form autocomplete="off">
                <input
                  :disabled="
                    readOnly ||
                    (!isEnabled('fnSocietaTecniciEdit') && !proponiDati)
                  "
                  type="text"
                  name="nominativo_completo"
                  class="form-control"
                  placeholder=""
                  aria-label=""
                  @input="
                    inputFocus = index;
                    getPersone(persona.nominativo_completo);
                  "
                  v-model="persona.nominativo_completo"
                  autocomplete="off"
                />
              </form>
              <input
                type="hidden"
                class="form-control"
                placeholder=""
                aria-label=""
                v-model="persona.id_persona"
              />
              <ListPersone
                v-if="inputFocus === index"
                :list="legali"
                @hideList="
                  resetLegali();
                  inputFocus = null;
                "
                @selectedLegale="
                  persona.nominativo_completo = $event.name;
                  persona.id_persona = $event.value;
                  inputFocus = null;
                  resetLegali();
                "
                :focusOnInput="inputFocus === index"
              ></ListPersone>
              <svg
                v-if="
                  !readOnly &&
                  (isEnabled('fnSocietaTecniciEdit') ||
                    (!isEnabled('fnSocietaTecniciEdit') && proponiDati))
                "
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                class="dp__icon dp__clear_icon dp__input_icons"
                @click="
                  persona.nominativo_completo = '';
                  persona.id_persona = null;
                "
              >
                <path
                  d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                ></path>
                <path
                  d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="col-md-3 align-self-center">
            <SelectInput
              :disabled="
                readOnly || (!isEnabled('fnSocietaTecniciEdit') && !proponiDati)
              "
              name="carica"
              id="carica"
              :options="tipo_tecnico"
              :value="persona.id_tipo_tecnico"
              @changeSelect="persona.id_tipo_tecnico = parseInt($event)"
            />
          </div>
          <div class="col-md-2 align-self-center">
            <Datepicker
              v-model="persona.dataInizio"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              locale="it"
              autoApply
              @update:model-value="
                persona.dataInizio = fromUTCtoISODate($event)
              "
              :disabled="readOnly"
            />
          </div>
          <div class="col-md-2 align-self-center">
            <Datepicker
              v-model="persona.dataFine"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              locale="it"
              autoApply
              @update:model-value="persona.dataFine = fromUTCtoISODate($event)"
              :disabled="readOnly"
            />
          </div>
          <div class="col-md-1 fw-bold text-center">
            <div class="row">
              <div class="col">
                <Popper
                  v-show="!persona.Has_Albo || !persona.Has_Tessera"
                  arrow
                  :content="
                    !persona.Has_Albo && persona.Has_Tessera
                      ? 'nessuna iscrizione albo'
                      : !persona.Has_Tessera && persona.Has_Albo
                      ? 'nessuna tessera'
                      : 'tessera e albo mancanti'
                  "
                >
                  <button>
                    <i
                      class="bi bi-info-circle-fill text-center text-danger"
                    ></i>
                  </button>
                </Popper>
              </div>
              <div class="col">
                <button
                  v-if="!readOnly && isEnabled('fnSocietaTecniciEdit')"
                  class="badge bg-light-danger rounded fs-5"
                  @click.prevent="elencoTecnici.splice(index, 1)"
                >
                  <i class="bi bi-trash text-danger fs-5"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mb-4 mt-4 text-gray-600" />
    <div v-for="(number, index) in count" :key="index">
      <AddTecnico
        v-if="number.render"
        :tecnico="number.tecnico"
        @deleteItem="
          count.splice(index, 1);
          changeValue = !changeValue;
        "
        @setPersona="number.tecnico = $event"
        :key="changeValue"
        :index="index + elencoTecnici.length"
      />
    </div>
    <div
      v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
      class="fw-bold text-center pb-6"
    >
      <button
        class="fw-bold fs-6 text-gray-700"
        @click.prevent="count.push({ render: true })"
      >
        <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi membro
      </button>
    </div>
  </div>

  <ModalAddPersona :fromTecnici="true" />

  <!--end::details View-->
  <div v-if="!readOnly && puo_utilizzare_pulsanti_sezioni" class="text-end">
    <button
      type="button"
      class="badge bg-secondary rounded blueFit fs-5"
      @click="editAllTecnici"
      :disabled="disableUpdate == 1"
    >
      Salva Modifiche
    </button>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddTecnico from "@/components/components-fit/società/tecnici/AddTecnico.vue";
import ModalAddPersona from "@/components/components-fit/persone/ModalAddPersona.vue";
// import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { useStore } from "vuex";
// import ListPersone from "@/components/components-fit/utility/ListPersone.vue";
import ListPersone from "@/components/components-fit/utility/ListPersoneTest.vue";
import {
  useFormatDateYMD,
  format,
  fromUTCtoISODate,
} from "@/composables/formatDate.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Popper from "vue3-popper";
import isEnabled from "@/composables/isEnabled.js";

import {
  editTecnico,
  addTecnico,
  listTecnici,
} from "../../../requests/tecniciSocietaReq";
import { alertFailed } from "@/composables/swAlert";

export default defineComponent({
  name: "tecnici-societa",
  components: {
    AddTecnico,
    SelectInput,
    Datepicker,
    ListPersone,
    ModalAddPersona,
    Loading,
    Popper,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const loadedTecnici = ref(false); //tipo_tecnico

    const elencoTecnici = ref([]);

    const tipo_tecnico = computed(() =>
      store.getters.getStateFromName("tipo_tecnico")
    );
    const keys = ref("tt");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (!tipo_tecnico.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagioneSelected = computed(() => store.getters.stagioneSelected);
    const proponiDati = ref(0);
    const loadListTecnici = (id, year) => {
      loadedTecnici.value = false;
      elencoTecnici.value.splice(0, elencoTecnici.value.length);
      listTecnici(id, year).then((res) => {
        if (res.data.statusCode === 400) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          loadedTecnici.value = true;
          return;
        }
        proponiDati.value = res.data.proponiDati;
        res.data.results.forEach((element) => {
          element.dataInizio =
            element.dataInizio != null
              ? useFormatDateYMD(element.dataInizio)
              : null;
          element.dataFine =
            element.dataFine != null
              ? useFormatDateYMD(element.dataFine)
              : null;
          elencoTecnici.value.push(element);
        });
        elencoTecnici.value.forEach((element) => {
          element.nominativo_completo = `${element.cognome}, ${element.nome} ${element.data_nascita}`;
        });
        loadedTecnici.value = true;
      });
    };

    const getPersone = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };
    const inputFocus = ref(null);

    loadListTecnici(props.societa.id, stagioneSelected.value);
    // cambio lista consiglio al cambio di stagione
    watch(stagioneSelected, (newVal) => {
      // if (props.societa)
      loadListTecnici(props.societa.id, newVal);
    });

    const addTecnicoRequest = (
      id_persona,
      id_tipo_tecnico,
      dataInizio,
      dataFine,
      id_settore
    ) => {
      addTecnico(
        id_settore,
        id_persona,
        id_tipo_tecnico,
        dataInizio,
        dataFine,
        stagioneSelected.value,
        props.societa.id
      );
    };

    const disableUpdate = ref(0);

    const isLoading = ref(false);
    const fullPage = ref(true);

    const editAllTecnici = async () => {
      /*elencoTecnici.value.forEach(async (persona) => {

        await editTecnico(persona);
      });

      count.value.forEach((element) => {
        addTecnicoRequest(
          element.tecnico.id_persona,
          element.tecnico.id_tipo_tecnico,
          element.tecnico.dataInizio,
          element.tecnico.dataFine,
          element.tecnico.id_settore
        );
      });*/

      isLoading.value = true;

      disableUpdate.value = 1;
      let values = {
        anno: stagioneSelected.value,
        id_societa: props.societa.id,
      };
      values.tecnici = [];

      elencoTecnici.value.forEach(async (persona) => {
        let richiestaElement = {};
        richiestaElement.id = persona.id;
        richiestaElement.id_persona = persona.id_persona;
        richiestaElement.id_tipo_tecnico = persona.id_tipo_tecnico;
        richiestaElement.dataInizio = persona.dataInizio;
        richiestaElement.dataFine = persona.dataFine;
        values.tecnici.push(richiestaElement);
      });

      count.value.forEach((element) => {
        if (
          element.tecnico != null &&
          element.tecnico != "" &&
          element.tecnico != undefined
        ) {
          let richiestaElement = {};
          richiestaElement.id = null;
          richiestaElement.id_persona = element.tecnico.id_persona;
          richiestaElement.id_tipo_tecnico = element.tecnico.id_tipo_tecnico;
          richiestaElement.dataInizio = element.tecnico.dataInizio;
          richiestaElement.dataFine = element.tecnico.dataFine;
          values.tecnici.push(richiestaElement);
        }
      });

      let responseAdd = editTecnico(values);
      responseAdd
        .then((value) => {
          //loader.hide();
          disableUpdate.value = 0;
          if (parseInt(value.data.statusCode) == 200) {
            Swal.fire({
              html: "Operazione completata con successo",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
              },
            });
            count.value = [];
            loadListTecnici(props.societa.id, stagioneSelected.value);
          } else {
            const strMsg = ref(value.data.message);
            Swal.fire({
              html: "Si sono verifica i seguenti errori:<br />" + strMsg.value,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Chiudi",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        })
        .catch(() => {
          //loader.hide();
          disableUpdate.value = 0;
          const strMsg = ref("Si è verificato un errore. Riprovare più tardi");
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Chiudi",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          //loader.hide();
          isLoading.value = false;
        });
    };

    //watch changes di stagione per resettare gli oggetti!!

    const count = ref([]);
    const changeValue = ref(false);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Tecnici Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });

    return {
      fromUTCtoISODate,
      changeValue,
      inputFocus,
      legali: computed(() => store.getters.personeSocieta),
      // nomeLegale,
      getPersone,
      format,
      resetLegali,
      spliceLegali,
      addTecnicoRequest,
      editTecnico,
      editAllTecnici,
      elencoTecnici,
      loadedTecnici,
      tipo_tecnico,
      // id_persona,
      // id_qualifica,
      // dataInizio,
      // dataFine,
      // nominativo_completo,
      count,
      // setPersona,
      disableUpdate,
      isLoading,
      fullPage,
      proponiDati,
      stagioneSelected,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      isEnabled,
      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
});
</script>

<style scoped>
/* tooltip */
:deep(.popper) {
  background: #dd6e89 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #dd6e89 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #dd6e89 !important;
  left: 0px !important;
}
</style>
